import React from "react";
import { Header } from "../../internal/Header/Header";
import ActionBar from "../../common/ActionBar";
import CustomTable from "../../common/CustomTable/CustomTable";
import styled, { css } from "styled-components";
import CaseService from "../../../services/CaseService";
import queryString from "query-string";
import { useState } from "react";
import _ from "lodash";
import { useRef } from "react";
import { getFileName, parseTimeStamp } from "../../../helpers/functions";
import moment from "moment";
import labels from "../../../helpers/labels.json";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { ArrowDownward } from "@material-ui/icons";

async function getUploads(query = "") {
  try {
    const response = await CaseService.bulkUpload(query);
    return { ...response };
  } catch (error) {
    return error;
  }
}

const columns = [
  {
    field: "url",
    title: labels.file,
    sorting: false,
    render: (rowData) => decodeURI(getFileName(rowData.url)),
    headerStyle: {
      paddingLeft: "26px",
    },
    cellStyle: {
      paddingLeft: "26px",
      width: 250,
    },
  },
  {
    field: "entity.name",
    title: labels.uploaded_for_party,
    sorting: false,
  },
  {
    field: "owner.name",
    title: labels.uploaded_by,
    sorting: false,
  },
  {
    field: "created_at",
    title: labels.uploaded_date,
    sorting: true,
    render: (rowData) =>
      moment(parseTimeStamp(rowData.created_at)).format("DD/MM/YYYY"),
    cellStyle: {
      width: 60,
      textAlign: "center",
    },
    headerStyle: {
      width: 60,
      textAlign: "center",
    },
  },
  {
    field: "updatedFileWithCaseId",
    title: "File with caseId",
    sorting: false,
    render: (rowData) => (
      <HyperLink
        text={rowData?.updatedFileWithCaseId}
        onClick={() =>
          rowData?.updatedFileWithCaseId
            ? window.open(rowData?.updatedFileWithCaseId)
            : {}
        }
      >
        {rowData?.updatedFileWithCaseId ? <ArrowDownward /> : "-"}
      </HyperLink>
    ),
    cellStyle: {
      width: 60,
      textAlign: "center",
    },
    headerStyle: {
      width: 60,
      textAlign: "center",
    },
  },
  {
    field: "action",
    title: labels.actions.substring(0, labels.actions.length - 1),
    sorting: false,
    render: (rowData) => (
      <HyperLink onClick={() => window.open(rowData.url)}>
        {labels.download}
      </HyperLink>
    ),
    cellStyle: {
      width: 60,
      textAlign: "center",
    },
    headerStyle: {
      width: 60,
      textAlign: "center",
    },
  },
];

export default function BulkUploads() {
  const [state, setState] = useState();
  const MTRef = useRef();

  const data = (query) =>
    new Promise((resolve, reject) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
        query: query.search,
      };
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getUploads(stringParams)
        .then((result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  return (
    <Header
      showSidebar
      selectedItem={labels.cases}
      selectedSubItem={labels.bulk_uploads}
    >
      <ActionBar
        breadcrumbs={[
          _.startCase(labels.cases),
          _.startCase(labels.bulk_uploads),
        ]}
      />
      <Padding>
        <CustomTable
          {...{
            data,
            state,
            MTRef,
            columns,
          }}
          hidePagination={state?.lastPage === 1}
          pageSize={state?.data?.length ? state?.data?.length : 10}
          singularTitle=""
          noToolbar
        />
      </Padding>
    </Header>
  );
}

const Padding = styled.div`
  padding: 35px;
`;

const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: ${({ text }) => (text ? "underline" : "")};
  margin-left: 0px;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `}
`;
